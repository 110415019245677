<template>
    <section class="section">
        <h2>デフォルト設定</h2>

        <inline-loader v-if="account_loading"></inline-loader>
        <form class="form" @submit.prevent="saveAccount" v-else>
            <div class="row mb-3">
                <div class="col-md-6">
                    <label class="form-label">リードタイム</label>
                    <div class="input-group">
                        <input type="number" class="form-control" v-model="account.default_lead_time" required>
                        <div class="input-group-text">日</div>
                    </div>
                </div>
                <div class="col-md-6">
                    <label class="form-label">サブコンディション</label>
                    <form-select
                        v-model="account.default_sub_condition"
                        :options="sub_condition_options"
                        empty_option="--選択--"
                        :required="true"
                    ></form-select>
                </div>
                <div class="col-md-6">
                    <label class="form-label">利益率</label>
                    <div class="input-group">
                        <input type="number" class="form-control" step="0.1" v-model="account.default_profit_ratio">
                        <div class="input-group-text">％</div>
                    </div>
                </div>
                <div class="col-md-6">
                    <label class="form-label">送料</label>
                    <div class="input-group">
                        <input type="number" class="form-control" v-model="account.default_shipping_fee">
                        <div class="input-group-text">円</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-6">
                    <label class="form-label">配送パターンID</label>
                    <div class="input-group">
                        <input type="text" class="form-control" v-model="account.default_shipping_group">
                    </div>
                </div>
            </div>
            <button type="submit" class="btn btn-primary">更新</button>
        </form>
    </section>

    <section class="section">
        <h2>コンディションノート テンプレート設定</h2>

        <inline-loader v-if="note_loading"></inline-loader>
        <template v-else>
            <form class="row mb-3" v-for="note of template_notes" :key="note.template_note_id" @submit.prevent="editNote(note)">
                <div class="col-md-6">
                    <input type="text" class="form-control" v-model="note.template_name">
                </div>
                <div class="col-md-16">
                    <textarea class="form-control" rows="5" v-model="note.template_content"></textarea>
                </div>
                <div class="col-md-2">
                    <div class="mb-3"><button type="submit" class="btn btn-primary">更新</button></div>
                    <div class=""><button type="button" @click="removeNote(note)" class="btn btn-outline-danger">削除</button></div>
                </div>
            </form>
        </template>
        <form class="row" @submit.prevent="addNote(note)">
            <div class="col-md-6">
                <input type="text" class="form-control" v-model="new_note.template_name" placeholder="テンプレート名">
            </div>
            <div class="col-md-16">
                <textarea class="form-control" rows="5" v-model="new_note.template_content"></textarea>
            </div>
            <div class="col-md-2">
                <button type="submit" class="btn btn-primary">更新</button>
            </div>
        </form>
    </section>

    <section class="section">
        <h2>コンディションノート デフォルト設定</h2>

        <inline-loader v-if="note_loading"></inline-loader>
        <template v-else>
            <div class="row">
                <div class="col-md-6">
                    <select class="form-control" v-model="default_template_note_id">
                        <option :value="null">-- デフォルトなし --</option>
                        <template v-for="option in template_note_options" :key="option">
                            <option :value="option.key">{{option.label}}</option>
                        </template>
                    </select>
                </div>
                <div class="col-md-2">
                    <button type="button" class="btn btn-primary" @click="updateDefaultNote()">更新</button>
                </div>
            </div>
        </template>
    </section>
</template>

<script>
import InlineLoader from '@/shared/components/tool/InlineLoader';
import FormSelect from '@/shared/components/form/FormSelect.vue';
import Account from '@/shared/models/entities/account';
import TemplateNote from '@/shared/models/entities/template-note';
import SubCondition from '@/shared/models/enums/sub-condition';

export default {
    name: 'PageSettingInventory',
    components: {
        InlineLoader,
        FormSelect,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage'
    ],
    data() {
        return {
            // loading
            account_loading: true,
            note_loading: true,

            account: new Account(),
            new_note: new TemplateNote(),
            template_notes: [],
            default_template_note_id: null,

            // options
            sub_condition_options: SubCondition.options(),
            template_note_options: [],
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.fetchAccount();
            this.fetchTemplateNotes();
        },
        fetchAccount() {
            this.account_loading = true;
            this.$http.get('/me/account')
            .then((response) => {
                this.account = new Account(response.data);
            })
            .finally(() => {
                this.account_loading = false;
            });
        },
        fetchTemplateNotes() {
            this.note_loading = true;
            this.$http.get('/template-notes')
            .then((response) => {
                for (let row of response.data) {
                    let template_note = new TemplateNote(row);
                    this.template_notes.push(template_note);
                    this.template_note_options.push({
                        key: template_note.template_note_id,
                        label: template_note.template_name
                    });
                    if (template_note.is_default === 1) {
                        this.default_template_note_id = template_note.template_note_id;
                    }
                }
            })
            .finally(() => {
                this.note_loading = false;
            });
        },
        saveAccount() {
            this.startScreenLoading();
            this.$http.put('/me/account', this.account)
            .then((response) => {
                this.account = new Account(response.data);
                this.$store.commit('user/auth/setAccount', {
                    account: response.data,
                });
                this.showMessage('更新しました');
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },

        addNote() {
            this.startScreenLoading();
            this.$http.post('/template-notes', this.new_note)
            .then((response) => {
                let new_template_note = new TemplateNote(response.data);
                // 一覧にpush
                this.template_notes.push(new_template_note);
                // デフォルト選択肢にpush
                this.template_note_options.push({
                    key: new_template_note.template_note_id,
                    label: new_template_note.template_name
                });
                this.template_note_options.sort((a, b) => {
                    return a.key < b.key ? -1 : 1;
                });

                this.new_note = new TemplateNote();
                this.showMessage('登録しました');
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        editNote(note) {
            this.startScreenLoading();
            this.$http.put('/template-notes/' + note.template_note_id, note)
            .then(() => {
                this.showMessage('更新しました');
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        removeNote(note) {
            this.startScreenLoading();
            this.$http.delete('/template-notes/' + note.template_note_id)
            .then(() => {
                // 一覧から削除
                let note_index = this.template_notes.findIndex((row) => row.template_note_id === note.template_note_id);
                this.template_notes.splice(note_index, 1);
                // デフォルト選択肢から削除
                let option_index = this.template_note_options.findIndex((row) => row.template_note_id === note.template_note_id);
                this.template_note_options.splice(option_index, 1);
                this.default_template_note_id = null;

                this.showMessage('削除しました');
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        updateDefaultNote() {
            this.startScreenLoading();
            this.$http.put('/template-notes/default', {
                default_template_note_id: this.default_template_note_id
            })
            .then(() => {
                this.showMessage('更新しました');
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
    }
}
</script>

<style scoped>

</style>
